import { isEmpty, isObject } from 'lodash';
import crypto from 'crypto';

export const scrollToFirstError = (dataError: object, id?: string) => {
    // if(isEmpty(dataError) ) return;
    const firstNamePrefix = Object.keys(dataError)?.[0] || '';
    const _id = [id, firstNamePrefix].filter((item) => !!item).join('.');
    if (
        !isEmpty(dataError[firstNamePrefix as keyof typeof dataError]) &&
        isObject(dataError[firstNamePrefix as keyof typeof dataError])
    ) {
        scrollToFirstError(dataError[firstNamePrefix as keyof typeof dataError], _id);
    } else {
        const firstErrorElement = document.getElementById(_id) as unknown as HTMLInputElement;
        if (!isEmpty(firstErrorElement)) {
            firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }
};

export const hashEmail = (email: string) => {
    if (!email) return '';
    return crypto.createHash('sha256').update(email.trim().toLowerCase()).digest('hex');
};

export function hexToRgb(hex: string) {
    let bigint = parseInt(hex.slice(1), 16);

    let r = (bigint >> 16) & 255;

    let g = (bigint >> 8) & 255;

    let b = bigint & 255;

    return { r, g, b };
}

export function getLuminance(r: number, g: number, b: number) {
    let a = [r, g, b].map((v) => {
        v /= 255;

        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });

    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

export function parseRgbString(rgb: string): { r: number; g: number; b: number } {
    const result = rgb.match(/\d+/g);
    if (result && result.length === 3) {
        const [r, g, b] = result.map(Number);
        return { r, g, b };
    }
    throw new Error('Invalid RGB string');
}

export function getContrastingTextColor(color: string) {
    if (!color) return '#000000';

    let { r, g, b } = hexToRgb(color);

    let luminance = getLuminance(r, g, b);

    return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export function getFromCookie(key: string) {
    const cookies = document.cookie.split('; ');
    const fbclidCookie = cookies.find((cookie) => cookie.startsWith(key + '='));
    return fbclidCookie ? fbclidCookie.split('=')[1] : null;
}
export function setFbclidCookie() {
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (fbclid) {
        document.cookie = `fbclid=${fbclid}; path=/; max-age=2592000;`; // Lưu 30 ngày
    }
}

export async function fetchUserIp() {
    const response = await fetch('/api/get-ip');
    const data = await response.json();
    return data.ip;
}
