import classNames from 'classnames';
import { useFormik } from 'formik';
import { FC, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import Button from '~/components/Button';
import Container from '~/components/Container';
import Input from '~/components/Input';
import Colors from '~/constants/colors';
import { Identifiers } from '~/constants/enum';
import { SUBSCRIBE_EMAIL_GQL } from '~/data/home';
import useClient from '~/hooks/useClient';
import useCmsBlocks from '~/hooks/useCmsBlocks';
import { UserAtom } from '~/services/user';
import { replaceClassNames } from '~/utils/converters';
import { isValidEmail } from '~/utils/validators';
import styles from './NewsLetter.module.scss';
import { CartAtom } from '~/services/cart';
import cartAtom from '~/services/cart/cart.atom';

interface NewsLetterProps {
    className?: string;
    choices?: { label: string; value: string }[];
    image?: string;
}

const NewsLetter: FC<NewsLetterProps> = ({ className, choices, image }) => {
    const currentUser = useRecoilValue(UserAtom.currentUser);
    const Cart = useRecoilValue(CartAtom.cartSelector);
    const isClient = useClient();
    const { cmsBlocks, loading } = useCmsBlocks([Identifiers.Newsletter, Identifiers.NewsletterBackground]);

    const [mutationNewLetter] = useMutation(SUBSCRIBE_EMAIL_GQL);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: { interested: 't-shirt', email: '' },
        validate(values) {
            const errors: Record<string, string> = {};
            if (!values.email?.trim()) {
                errors.email = 'Please enter your email address';
            } else if (!isValidEmail(values.email)) {
                errors.email = 'Please enter a valid email address';
            }
            return errors;
        },
        onSubmit: async (values) => {
            return await mutationNewLetter({
                variables: {
                    email: values?.email
                },
                onCompleted(data, clientOptions) {
                    toast.success('Subscribe successfully!');
                    formik.resetForm();
                },
                onError(error, clientOptions) {
                    toast.error(error.message);
                }
            });
        }
    });

    const content = get(cmsBlocks, [Identifiers.Newsletter, 'content'], null);

    const background = cmsBlocks[Identifiers.NewsletterBackground]?.content;

    const options = useMemo(() => {
        if (choices) return choices;

        try {
            // const currentWidget =  widgets[indexWidget]
            // const regexOptions = /(?<=options=").*?(?=")/g
            // const idTab =  currentWidget?.match(regexOptions)?.[0]
            const regexCustomVar = new RegExp('(?<=options=).*?(?=}})', 'g');
            const options = content.match(regexCustomVar)?.[0];
            const result = JSON.parse(options);

            return result;
        } catch (error) {
            return [];
        }
    }, [content]);

    // const options = choices
    //   ? choices
    //   : [
    //       { label: "T-SHIRT", value: "t-shirt" },
    //       { label: "JACKET", value: "jacket" },
    //       { label: "T-CUSTOM", value: "t-custom" },
    //     ];

    if (!isClient) return <div />;
    if (!!loading) return <div />;
    if (!content) return <div />;
    if (!!currentUser?.token || !content || !!loading || !isClient) {
        return <div />;
    }

    return (
        <div
            style={{ background: image ? `url(${image})` : `${Colors.GREY_500}` }}
            className={classNames(className, styles.newsletter)}
        >
            <div
                className="object-cover absolute w-full h-full inset-0 text-transparent"
                dangerouslySetInnerHTML={{ __html: background }}
            />
            <Container className="flex flex-col items-center justify-between gap-10 lg:flex-row">
                <div
                    dangerouslySetInnerHTML={{
                        __html: replaceClassNames(content, {
                            NewsLetter: styles
                        })
                    }}
                />
                <div className={styles.email_box}>
                    <div className="flex items-center gap-3">
                        <Input
                            className="flex-1 text-white"
                            variant="transparent"
                            type="email"
                            placeholder="Type your email"
                            error={formik.errors.email}
                            {...formik.getFieldProps('email')}
                        />
                        <Button variant="secondary" loading={formik.isSubmitting} onClick={formik.submitForm}>
                            SIGN UP
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default NewsLetter;
