import { useQuery } from '@apollo/client';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import React from 'react';
import SEOTags from '~/components/SEOTags';
import { Identifiers } from '~/constants/enum';
import { QUERY_GET_CMS_PAGE } from '~/data/cms/gql';
import { GET_SLIDER_GQL, QUERY_GET_CMS_BLOCKS_SSR } from '~/data/home';
import { GET_SCHEMA_HOME } from '~/data/schemas/schema.gql';
import useClient from '~/hooks/useClient';
import Layout from '~/layouts/ThemeLayout';
import ReMaketing, { REMARKETING_HOME } from '~/services/tracking/ReMaketing';
import styles from '~/styles/pages/Home.module.scss';
import HomeSchema from '~/tracking-pages/home.schema';
import { initializeApollo } from '~/utils/apollo-client';
import { getPageStruct, renderSectionByKey } from '~/utils/pageTwo';
import { getTheme } from '~/utils/theme';

const pageStruct = getPageStruct(getTheme(), 'home');
const sections: any = pageStruct?.children
    ?.sort((a: any, b: any) => a.order - b.order)
    .map((item: any) => {
        const Section = renderSectionByKey(item.id);
        return {
            id: item.id,
            order: item.order,
            dataKey: item.dataKey,
            identifier: item.identifier,
            component: Section ?? null
        };
    });

const Home = ({ meta, configSchema }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    const isClient = useClient();
    const { data: dataCmsBlocks } = useQuery(QUERY_GET_CMS_BLOCKS_SSR, {
        variables: {
            identifiers: [
                Identifiers.HomeBrand,
                Identifiers.ProductMatching,
                Identifiers.RecentlyView,
                Identifiers.TopCategories,
                Identifiers.FeatureProduct,
                Identifiers.NewCollection,
                Identifiers.Recommended,
                Identifiers.Footer
            ]
        }
    });
    const cmsBlocks = dataCmsBlocks?.data?.cmsBlocks?.items?.filter((item: any) => !!item);

    const newTitle = [
        process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
        meta?.meta_title || process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE
    ]
        .filter((i) => !!i)
        .join(' | ');

    // if (!isClient) return null;
    return (
        <Layout isUseMinHeight>
            <SEOTags metaTitle={newTitle} description={meta?.meta_description} keywords={meta?.meta_keywords} />
            <HomeSchema config={configSchema} />
            <ReMaketing gtagType={REMARKETING_HOME} />
            <h1 className="hidden">{newTitle}</h1>
            <div className={styles.homepage}>
                {sections.map((section: any, index: number) => {
                    if (
                        section.identifier &&
                        !!cmsBlocks?.length &&
                        cmsBlocks.findIndex((cms: any) => cms.identifier === section.identifier) === -1
                    ) {
                        return null;
                    }

                    const Section = section.component;

                    return <React.Fragment key={`section-${index}`}>{!!Section ? <Section /> : null}</React.Fragment>;
                })}
            </div>
        </Layout>
    );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
    const apolloClient = initializeApollo({
        httpLinkProps: { useGETForQueries: true }
    });
    try {
        const { data } = await apolloClient.query({
            query: QUERY_GET_CMS_PAGE,
            variables: {
                identifier: 'home'
            }
        });

        const { data: dataSchema } = await apolloClient.query({
            query: GET_SCHEMA_HOME
        });
        const fetchSlider = await apolloClient.query({
            query: GET_SLIDER_GQL
        });

        return {
            props: {
                meta: data?.cmsPage,
                slider: fetchSlider?.data?.getSlider?.items,
                configSchema: dataSchema?.MarketingConfig?.schemaHomepage,
                // cmsBlocks: cmsBlocks?.data?.cmsBlocks?.items?.filter((item: any) => !!item),
                initialApolloState: apolloClient.cache.extract()
            }
        };
    } catch (error) {
        return {
            props: {
                meta: {},
                storeConfig: {},
                cmsData: null,
                initialApolloState: null,
                homeStructs: []
            }
        };
    }
};
